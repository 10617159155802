//@flow
import React, { useEffect, useMemo } from 'react';
import Box from "@mui/material/Box";
import { useExhibition } from "../../hooks/exhibitions/useExhibition";
import { useCountries } from "../../hooks/countries/useCountries";
import { useInterests } from "../../hooks/interests/useInterests";
import { useCategories } from "../../hooks/categories/useCategories";
import useQueryParams from "../../hooks/useQueryParams";
import useVisitorIsValid from "../../hooks/visitor/useVisitorIsValid";
import _ from 'lodash'
import { preRegistrationIsOpen } from "../../helpers/helpers";
import ContentLoader from "../../components/contentLoader/contentLoader";
import { useMultilingualText } from "../../hooks/useMultilingualText";
import { IMAGE_LOGO } from "../../assets/images";
import Toolbar from "@mui/material/Toolbar";
import LanguageSelect from "../../components/languageSelect/languageSelect";
import DisplayForm from "../../components/displayForm/displayForm";
import useUserData from "../../hooks/user/useUserData";
import { useTranslation } from "react-i18next";
import useVisitorValidIp from "../../hooks/visitor/useVisitorValidIp";
import { CatalogueLanguages } from "../../helpers/constants";

const PreRegistration = () => {
	const queryParams = useQueryParams()
	const id = queryParams.get('event')
	const key = queryParams.get('key')
	const isPreview = queryParams.get('isPreview')
	const urlLanguage = queryParams.get('language')
	const { exhibition, loading } = useExhibition(id)
	const { loading: visitorStatusLoading, visitorIsValid } = useVisitorIsValid({ exhibitionId: id, key })
	const { loading: ipValidLoading, visitorIpIsValid } = useVisitorValidIp({ exhibition, isPreview })
	const preRegistrationIsValid = useMemo(() => !(_.isEmpty(exhibition)) ? preRegistrationIsOpen(exhibition) && visitorIpIsValid : false, [exhibition, visitorIpIsValid])
	const {
		loading: countriesLoading,
		countries,
		phoneCodes,
		defaultCountry,
		defaultPhoneCode
	} = useCountries({ exhibition })
	const { interests } = useInterests(id)
	const { categories } = useCategories(id)
	
	const isLoading = loading || visitorStatusLoading || ipValidLoading || countriesLoading
	const imageUrl = useMultilingualText(IMAGE_LOGO)
	
	const { language } = useUserData()
	const { i18n } = useTranslation()
	
	useEffect(() => {
		i18n.changeLanguage(urlLanguage ? urlLanguage : language)
	}, [i18n, language, urlLanguage])
	
	useEffect(() => {
		let tag = exhibition?.googleTagCode
		if (tag) {
			const script = document.createElement('script');
			script.async = true;
			script.src = `https://www.googletagmanager.com/gtag/js?id=${tag}`;
			document.head.appendChild(script);
			
			const inlineScript = document.createElement('script');
			inlineScript.innerHTML = `
			  window.dataLayer = window.dataLayer || [];
			  function gtag(){dataLayer.push(arguments);}
			  gtag('js', new Date());
			  gtag('config', '${tag}');
			`;
			document.head.appendChild(inlineScript);
			return () => {
				document.head.removeChild(script);
				document.head.removeChild(inlineScript);
			};
		}
	}, [exhibition?.googleTagCode])
	
	// useEffect(() => {
	// 	let tag = ''
	// 	if (id === '35') tag = 'GTM-MSQ4VNJ' // METAL 2023
	// 	if (id === '36') tag = 'GTM-MQNCW6D'
	// 	const scriptExists = document.getElementsByClassName('gtagScript')
	// 	if (tag && scriptExists.length === 0) {
	// 		const script = document.createElement('script')
	// 		script.classList.add('gtagScript')
	// 		script.innerHTML = `(function (w, d, s, l, i) {
	//             w[l] = w[l] || [];
	//             w[l].push({
	//                 'gtm.start':
	//                     new Date().getTime(), event: 'gtm.js'
	//             });
	//             var f = d.getElementsByTagName(s)[0],
	//                 j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : '';
	//             j.async = true;
	//             j.src =
	//                 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
	//             f.parentNode.insertBefore(j, f);
	//         })(window, document, 'script', 'dataLayer', '${tag}')`
	// 		document.head.appendChild(script)
	// 		const noScript = document.createElement('noscript')
	// 		const iframe = document.createElement('iframe');
	// 		iframe.setAttribute('src', `https://www.googletagmanager.com/ns.html?id=${tag}`);
	// 		iframe.setAttribute('height', '0');
	// 		iframe.setAttribute('width', '0');
	// 		iframe.style.display = 'none';
	// 		iframe.style.visibility = 'hidden';
	// 		noScript.appendChild(iframe);
	// 		document.body.appendChild(noScript);
	// 	}
	// }, [])
	
	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
				height: '100%',
				minHeight: '100vh'
			}}
			bgcolor={'#1A2027'}
		>
			{exhibition ? (
				<React.Fragment>
					{!(window.location !== window.parent.location) && <Toolbar sx={{
						backgroundColor: '#dfecff',
						width: '100%',
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						marginBottom: '2px'
					}}>
						<img src={imageUrl} alt="logo" style={{
							maxHeight: 62,
							paddingRight: exhibition?.catalogueLanguage === CatalogueLanguages.ALL ? '32px' : 0
						}}/>
						<LanguageSelect catalogueLanguage={exhibition.catalogueLanguage} urlLanguage={urlLanguage}/>
					</Toolbar>}
					<Box
						sx={{
							textAlign: 'center',
							width: '100%',
							paddingTop: '6px',
							display: 'flex',
							justifyContent: 'center'
						}}
					>
						<DisplayForm
							id={id}
							personalLinkVisitorKey={key}
							exhibition={exhibition}
							countries={countries}
							interests={interests}
							phoneCodes={phoneCodes}
							defaultCountry={defaultCountry}
							defaultPhoneCode={defaultPhoneCode}
							categories={categories}
							visitorIsValid={visitorIsValid}
							preRegistrationIsValid={preRegistrationIsValid}
						/>
					</Box>
				</React.Fragment>
			) : (
				<ContentLoader
					loading={isLoading}
					notFoundWhenNotLoading
				/>
			)}
		</Box>
	)
}

export default PreRegistration
